<template>
  <div>
    <banner :title="title" :subtitle="subtitle"></banner>
    <div class="pannel">
      <div class="title">
        <hr />
        <h3>公司简介</h3>
      </div>
      <article>
        <p v-html="content"></p>
      </article>
    </div>
    <div class="pannel">
      <div class="title">
        <hr />
        <h3>荣誉资质</h3>
      </div>
      <div class="img-grid">
        <div v-for="(item, idx) in glories" :key="idx">
          <div class="image-div">
            <img v-if="item.ImagePath" :src="item.ImagePath" :alt="item.Name" />
          </div>
          <div class="text-div">{{ item.Name }}</div>
        </div>
      </div>
    </div>
    <div class="pannel">
      <div class="title">
        <hr />
        <h3>联系我们</h3>
      </div>
      <div class="contact-us">
        <div class="underline">
          <h4 v-text="maincompany.CompanyName"></h4>
          <p v-text="maincompany.Address"></p>
        </div>

        <div class="underline">
          <h5 v-text="maincompany.ContactPhone"></h5>
          <p v-text="maincompany.ContactTime"></p>
        </div>
        <!-- <div v-for="(item, idx) in companies" :key="idx">
          <h4 v-text="item.CompanyName"></h4>
          <p v-text="item.Address"></p>
        </div> -->
      </div>
    </div>
    <div class="pannel">
      <baidu-map id="allmap" :scroll-wheel-zoom="true" @ready="mapReady">
        <bm-marker
          :position="{ lng: 119.051449, lat: 33.545265 }"
          animation="BMAP_ANIMATION_BOUNCE"
        ></bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import Banner from "../components/NormalBanner.vue";
import { get } from "../plugins/axios";
export default {
  components: { Banner },
  data() {
    return {
      title: "关于纷云",
      subtitle: "纷云天下，网通八方",
      content: "",
      glories: [],
      companies: [],
      maincompany: {
        CompanyName: "",
        Address: "",
        ContactPhone: "",
        ContactTime: "",
      },
      point: { lng: 119.051449, lat: 33.545265 },
    };
  },
  methods: {
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(119.051449, 33.545265);
      map.centerAndZoom(this.point, 18);
    },
  },
  mounted() {
    this.$nextTick(function () {
      get("/HomeApi/QueryAboutInfo")
        .then((data) => {
          this.content = data.profile.Content;
          this.glories = data.honorarys;
          this.companies = data.contactUs.filter(
            (f) => f.IsPartCompany === true
          );
          this.maincompany = data.contactUs.filter(
            (f) => f.IsPartCompany === false
          )[0];
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
};
</script>

<style lang="less" scoepd>
.pannel {
  max-width: 1024px;
  margin: 30px auto;
  font-size: 14px;

  .title {
    display: grid;
    grid-template-columns: 20px 1fr;
    hr {
      width: 3px;
      background: red;
      border: 0;
    }
    h3 {
      margin: 5px;
    }
  }

  // article p::before {
  //   content: "\00A0\00A0\00A0\00A0\00A0\00A0\00A0\00A0";
  // }
  article {
    margin-top: 20px;
  }
  article p {
    margin: 0;
    line-height: 2;
    text-indent: 2em;
  }

  h3 {
    font-size: 36px;
    font-weight: 600;
  }

  .img-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 450px);
    grid-auto-rows: 300px;
    grid-gap: 50px 50px;
    justify-content: center;

    .image-div {
      width: 100%;
      //background: #f2f2f2;
      border: 1px #eee solid;
      position: relative;
      text-align: center;
      padding: 8px;
      height: 100%;
    }
    .text-div {
      text-align: center;
      color: #333;
      margin: 5px 0px;
    }
    img {
      height: 100%;
    }
  }

  .contact-us {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 80px;
    align-items: flex-end;
    padding-left: 20px;
    p {
      color: #999;
      font-size: smaller;
      line-height: 2;
    }

    .underline {
      //border: solid #ccc;
      //border-width: 0 0 1px 0;
      //margin: 10px 0px;
    }

    h4 {
      font-size: 20px;
      font-weight: 450;
      margin: 0;
    }
    h5 {
      font-size: 15px;
      font-weight: 450;
      margin: 0;
    }
  }
}
#allmap {
  height: 600px;
  width: 1000px;
  margin: 20px 25px;
}
</style>